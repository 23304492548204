import React, { useContext } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { liveBlogCard } from 'lib/CustomPropTypes';

import { VerticalContext, LiveBlogContext } from 'lib/ContextTypes';
import InlineVideo from 'components/InlineVideo';
import SocialShareList from 'components/Social/ShareList';
import { Copy } from 'components/Social/UserActions/Copy';

import { CardByline } from 'components/Card/CardByline';
import { CardHeadline } from 'components/Card/CardHeadline';
import { CardTimestamp } from 'components/Card/CardTimestamp';

import { getSiteConfigs } from 'lib/vertical';
import './styles.themed.scss';
import { cardMarkup } from './CardUtils';


const Card = ({
  cardType,
  authors,
  content: {
    /**
     * markupAndEmbeds should be data from the API, and should be respected if
     * `visibleMarkupAndEmbeds` isn't passed in
     */
    markupAndEmbeds = [],
    visibleMarkupAndEmbeds,
    hiddenMarkupAndEmbeds,
  },
  date,
  headline: { primary: primaryHed },
  isHeadlineHidden = false,
  id,
  path,
  cardData: videoData,
  socialUrl,
  newActiveItem,
}) => {
  const vertical = useContext(VerticalContext);
  const { isLive } = useContext(LiveBlogContext);
  const [showHiddenMarkupAndEmbeds, setShowHiddenMarkupAndEmbeds] = React.useState(false);

  const cardClasses = classNames(
    'live-blog-card',
    'article-body__content',
    'w-100 mh0 mb8 fn',
    { newActiveItem },
  );

  const encodedSocialUrl = encodeURIComponent(socialUrl);

  const icid = 'ed_npd_nn_nn_np_liveblog';
  const dataTrackCardUrlIcid = 'live-blog-card-link';

  const { twitterIntentVia: via } = getSiteConfigs(vertical);

  const socialProps = {
    // eslint-disable-next-line max-len
    twitterHref: `https://twitter.com/intent/tweet?text=${encodeURIComponent(primaryHed)}&via=${via}&url=${encodedSocialUrl}&original_referer=${encodedSocialUrl}`,
    facebookHref: `https://www.facebook.com/sharer/sharer.php?u=${encodedSocialUrl}`,
    emailHref: `mailto:?subject=${primaryHed}&body=${encodedSocialUrl}`,
    classes: 'df justify-start lh-none',
    tracking: 'mbt_navbar_socialShare',
  };

  /**
   * if `visibleMarkupAndEmbeds` was not generated and passed in, but markupAndEmbeds exists, then
   * we should use that array instead, which should be data coming from the API directly
   */
  const cardBodyItems = visibleMarkupAndEmbeds || markupAndEmbeds;

  return (
    <div
      className={cardClasses}
      key={id}
      id={id}
      data-test="card-container"
      data-testid="card-container"
    >
      {date && (
        <CardTimestamp date={date} isLive={isLive} />
      )}
      {!isHeadlineHidden && primaryHed && (
        <CardHeadline headline={primaryHed} />
      )}
      <CardByline authors={authors} />
      <div
        data-icid={dataTrackCardUrlIcid}
        data-testid="card-body-container"
      >
        {cardMarkup({
          bodyItems: cardBodyItems,
          path,
          showHiddenMarkupAndEmbeds,
          setShowHiddenMarkupAndEmbeds,
        })}

        {showHiddenMarkupAndEmbeds
          ? (
            cardMarkup({ bodyItems: hiddenMarkupAndEmbeds, path })
          )
          : (
            <div className={classNames('hidden-show-more-content', { dn: !hiddenMarkupAndEmbeds?.length })}>
              {cardMarkup({ bodyItems: hiddenMarkupAndEmbeds, path })}
            </div>
          )}

        {cardType === 'VIDEO' && <InlineVideo video={videoData} />}
      </div>
      <div className="share-list__wrapper mt6">
        <SocialShareList {...socialProps} />
        <Copy contentURL={socialUrl} headline={primaryHed} icid={icid} className="share--card" />
      </div>
    </div>
  );
};

Card.propTypes = {
  ...liveBlogCard,
  socialUrl: PropTypes.string.isRequired,
  newActiveItem: PropTypes.bool,
};

Card.defaultProps = {
  newActiveItem: false,
};

export { Card };
