import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import Link from 'components/Link';
import { Save } from 'components/SocialShareMenu/Save';
import classNames from 'classnames';

import styles from './styles.module.scss';

const FeaturedRecipes = ({ embeddedRecipes }) => {
  const [hasOverflow, setHasOverflow] = useState(false);
  const [atBottom, setAtBottom] = useState(false);
  const scrollRef = useRef(null);

  useEffect(() => {
    const checkOverflow = () => {
      const { scrollHeight, clientHeight } = scrollRef.current;
      setHasOverflow(scrollHeight > clientHeight);
    };
    // remove fade when a user scrolls to the bottom of the div
    const handleScroll = () => {
      const { scrollTop, scrollHeight, clientHeight } = scrollRef.current;
      setAtBottom(scrollTop + clientHeight >= scrollHeight - 1);
    };

    checkOverflow();

    const scrollElement = scrollRef.current;
    scrollElement.addEventListener('scroll', handleScroll);

    return () => {
      scrollElement.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className={classNames(styles.wrapper, hasOverflow && !atBottom ? styles.fade : '')} data-activity-map="featured-recipes" data-testid="featuredRecipes">
      <div className={styles.textContent} ref={scrollRef} data-testid="recipeText">
        <h1 className={styles.header}>Recipes Featured in this Article</h1>
        <ul>
          {embeddedRecipes.map((item) => (
            <li className={styles.listItem}>
              <Save contentId={item.recipe.id} contentType="recipe" additionalClasses={styles.save} />
              <Link className={styles.link} to={item.recipe.url.primary} target="_blank" rel="noreferrer">
                {item.recipe.headline.primary}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

FeaturedRecipes.defaultProps = {
  embeddedRecipes: [],
};

FeaturedRecipes.propTypes = {
  embeddedRecipes: PropTypes.arrayOf(PropTypes.shape({})),
};

export { FeaturedRecipes };
