import React from 'react';

export default function BackToTopIcon(props) {
  return (
    <svg width="100%" height="100%" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_7608_926)">
        <path d="M8.5 14L8.5 4.66667" stroke="currentColor" strokeWidth="2" strokeLinecap="square" strokeLinejoin="round" />
        <path d="M3.83341 8.66675L8.50008 4.00008L13.1667 8.66675" stroke="currentColor" strokeWidth="2" strokeLinecap="square" />
        <rect x="1.83325" y="1.33325" width="13.3333" height="0.666667" fill="currentColor" />
      </g>
      <defs>
        <clipPath id="clip0_7608_926">
          <rect width="16" height="16" fill="white" transform="translate(0.5)" />
        </clipPath>
      </defs>
    </svg>
  );
}


