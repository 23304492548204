import React from 'react';
import get from 'lodash.get';
import PropTypes from 'prop-types';

import {
  author as AuthorPropType,
  person as PersonPropType,
} from 'lib/CustomPropTypes';

import {
  BylineList,
  BylineThumbnail,
} from 'components/Byline';

import styles from './styles.module.scss';

/**
 * @param {object} props
 * @param {(Author | Person)[]} props.authors
 */
const CardByline = ({ authors }) => {
  if (!authors || !authors.length) {
    return null;
  }

  const showThumbnail = authors.length === 1;

  // Account for different anatomies of the person object
  /** @type {Person} */
  const authorForThumbnail = get(authors[0], 'person', authors[0]);

  return (
    <div className={styles.cardByline} data-test="byline-container" data-testid="byline-container">
      {showThumbnail && (
        <BylineThumbnail
          additionalClasses={styles.bylineThumbnail}
          person={authorForThumbnail}
          position={1}
        />
      )}
      <div className={styles.inner}>
        <p className={styles.authors} data-test="byline" data-testid="byline">
          <BylineList authors={authors} />
        </p>
      </div>
    </div>
  );
};

CardByline.propTypes = {
  authors: PropTypes.arrayOf(
    AuthorPropType || PersonPropType,
  ),
};

CardByline.defaultProps = {
  authors: null,
};

export { CardByline };
