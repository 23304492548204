import React from 'react';
import PropTypes from 'prop-types';
import { article as ArticlePropType } from 'lib/CustomPropTypes';
import { Bacon } from 'components/packages/Bacon';
import { formatDataForBacon } from './relatedArticlesHelper';

import styles from './styles.module.scss';

const Related = ({ relatedArticles, vertical, primaryTaxonomyName }) => (
  <div className={`${styles.container} dn-print related-articles-container`} data-testid="related-articles">
    {relatedArticles && (
      <Bacon
        content={formatDataForBacon(relatedArticles, primaryTaxonomyName)}
        vertical={vertical}
        useAltTitle={vertical === 'today'}
      />
    )}
  </div>
);

Related.propTypes = {
  relatedArticles: PropTypes.arrayOf(ArticlePropType).isRequired,
  vertical: PropTypes.string.isRequired,
  primaryTaxonomyName: PropTypes.string,
};

Related.defaultProps = {
  primaryTaxonomyName: undefined,
};

export default Related;
