import React, { useEffect, useContext } from 'react';
import Breakpoints from 'lib/Breakpoints';
import { VerticalContext } from 'lib/ContextTypes';
import scrollTo from 'lib/scrollToElement';
import { stub as $t } from '@nbcnews/analytics-framework';

import './styles.themed.scss';

const BUTTON_CLICK_EVENT = 'mbt_backtotop_click';

const BackToTopButton = () => {
  const vertical = useContext(VerticalContext);

  // Register tracking event
  useEffect(() => {
    if (typeof $t !== 'undefined') {
      $t('register', BUTTON_CLICK_EVENT, { allowDuplicate: true });
    }
  }, []);

  // Handle button click
  const handleClick = (event) => {
    event.preventDefault();
    // Track click
    if (typeof $t !== 'undefined') {
      $t('track', BUTTON_CLICK_EVENT, {
        breakpoint: Breakpoints.isSorM() ? 'mobile' : 'desktop',
        vertical,
      });
    }
    // Scroll window
    scrollTo(0, 1000, 'easeInOut');
  };

  return (
    <button
      className="back-to-top-button"
      data-test="back-to-top-button"
      onClick={handleClick}
      type="button"
    >
      <svg
        width="62"
        height="62"
        viewBox="0 0 62 62"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#back_to_top)">
          <rect x="10" y="10" width="42" height="42" className="back-to-top-button__square" />
          <g clipPath="url(#back_to_top_clip)">
            <path
              d="M31 44.417V23.5466"
              stroke="white"
              strokeWidth="2"
              strokeLinecap="square"
              strokeLinejoin="round"
            />
            <path
              d="M20.5642 32.4912L30.9994 22.056L41.4346 32.4912"
              stroke="white"
              strokeWidth="2"
              strokeLinecap="square"
            />
            <rect x="16.0928" y="16.0928" width="29.8148" height="1.49074" fill="white" />
          </g>
        </g>
        <defs>
          <filter
            id="back_to_top"
            x="0"
            y="0"
            width="62"
            height="62"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="5" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <clipPath id="back_to_top_clip">
            <rect
              width="35.7778"
              height="35.7778"
              fill="white"
              transform="translate(13.1113 13.1113)"
            />
          </clipPath>
        </defs>
      </svg>
    </button>
  );
};

export { BackToTopButton };
