import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import SMS from 'components/Icon/SMS';
import { stripLeadingSlash } from 'lib/urlUtils';
import { useCopyToClipboard } from 'lib/Hooks';
import { LINK_COPIED_TEXT, SHARE_LINK_COPIED_TEXT } from 'lib/constants';
import { transformCardUrlForSocialShare } from 'lib/transformCardUrlForSocialShare';

import styles from './styles.module.scss';

/**
 *
 * @param {object} props
 * @param {string} props.contentURL
 * @param {string} props.embedHref
 * @param {string} props.headline
 * @param {boolean} props.isVideoPage
 * @param {string} props.publisher
 * @param {function?} props.track
 * @returns
 */
const Copy = (props) => {
  const {
    contentURL,
    embedHref,
    headline,
    isVideoPage,
    publisher,
    track,
    icid,
    tooltipAlign,
    additionalClasses,
  } = props;
  const copyIcon = isVideoPage ? 'icon-embed' : 'icon-link';

  const [isCopied, handleCopyToClipboard] = useCopyToClipboard(1500);
  const [copyText, setCopyText] = useState(LINK_COPIED_TEXT);
  /**
   *
   * @param {React.MouseEvent} event
   */
  const handleCopy = (event) => {
    let copyUrl = contentURL?.primary || contentURL;
    if (event.altKey && event.shiftKey) {
      // If alt/⌥ & Shift are pressed, use the specially formatted link
      // for sharing the individual blog post
      copyUrl = transformCardUrlForSocialShare(contentURL);
      setCopyText(SHARE_LINK_COPIED_TEXT);
    } else {
      setCopyText(LINK_COPIED_TEXT);
    }
    const { protocol, host } = window.location;
    const sanitizedHref = stripLeadingSlash(embedHref);
    // disabling es-lint for single line string formatting
    // eslint-disable-next-line max-len
    const embedMarkup = `<iframe loading='lazy' width='560' height='315' src='${protocol}//${host}/${sanitizedHref}' scrolling='no' frameborder='0' allowfullscreen></iframe>`;

    handleCopyToClipboard(isVideoPage ? embedMarkup : copyUrl);
  };

  return (
    <>
      {!isVideoPage
        && (
          <a
            href={`sms:?&body=${headline} via ${publisher} - ${contentURL}`}
            onClick={() => {
              if (typeof track === 'function') {
                track('sms');
              }
            }}
            rel="noopener noreferrer"
            target="_blank"
            className={`share__sms ${styles.share__sms}`}
            data-icid={icid}
            data-testid="sms-link"
          >
            <SMS />
          </a>
        )}
      <button
        className={classNames(
          'share__link-copy',
          `${styles['share__link-copy']}`,
          additionalClasses,
        )}
        onClick={(event) => handleCopy(event)}
        type="button"
        data-testid="share__link-copy"
      >
        <span
          className={classNames(
            'icon',
            `${copyIcon}`,
          )}
          data-test={`${copyIcon}`}
          data-testid={`${copyIcon}`}
        />
        <div
          data-test="share__link-copytext"
          data-testid="share__link-copytext"
          className={classNames(styles['share__copied-message'],
            {
              [styles.copied]: isCopied,
              [styles.alignRight]: tooltipAlign === 'right',
              [styles.alignCenter]: tooltipAlign === 'center',
            })}
        >
          <span className={`icon icon-alt-check ${styles['icon-alt-check']}`} />
          {copyText}
        </div>
        <textarea className={`${styles['share__copy-area']}`} tabIndex="-1" readOnly />
      </button>
    </>
  );
};

Copy.propTypes = {
  contentURL: PropTypes.string.isRequired,
  embedHref: PropTypes.string,
  headline: PropTypes.string,
  isVideoPage: PropTypes.bool,
  publisher: PropTypes.string,
  track: PropTypes.func,
  icid: PropTypes.string,
  tooltipAlign: PropTypes.string,
  additionalClasses: PropTypes.string,
};

Copy.defaultProps = {
  embedHref: '',
  headline: '',
  isVideoPage: false,
  publisher: '',
  track: undefined,
  icid: null,
  tooltipAlign: null,
  additionalClasses: '',
};

export { Copy };
