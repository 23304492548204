import React from 'react';
import PropTypes from 'prop-types';
import { markupAndEmbeds } from 'lib/CustomPropTypes';

import { cardMarkup } from 'components/Card/CardUtils';
import { Subhed } from './Subhed';

/**
 * CheckStep renders a heading and a set of body items using the cardMarkup utility function.
 *
 * @component
 * @param {Object} props - The properties that define the CheckStep component.
 * @param {string} props.heading - The heading of the CheckStep. Defaults to an empty string.
 * @param {Array} props.bodyItems - An array of objects representing the body items to be rendered by the cardMarkup utility function. Each object should follow the shape defined in the markupAndEmbeds custom prop type. Defaults to an empty array.
 *
 * @example
 * <CheckStep heading="Verdict" bodyItems={verdict.markupAndEmbeds} />
 *
 * @returns {React.Element} The rendered CheckStep component.
 */

export function CheckStep({ heading, bodyItems, className }) {
  return (
    <div className={className}>
      {heading && <Subhed>{heading}</Subhed>}
      {Boolean(markupAndEmbeds.length) && (
        cardMarkup({
          bodyItems,
        })
      )}
    </div>
  );
}

CheckStep.propTypes = {
  heading: PropTypes.string,
  bodyItems: markupAndEmbeds,
  className: PropTypes.string,
};

CheckStep.defaultProps = {
  heading: '',
  bodyItems: [],
  className: '',
};
