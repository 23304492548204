import React from 'react';
import classNames from 'classnames';

/**
 * @param {ReactElement} element - React element that gets assigned the classes
 * @param {string} classes - Space seperated classes
 *
 * @example
 *
 *     addClassesToElement(<Header />, 'className1 className2')
 */

export function addClassesToElement(element, classes) {
  if (!element) {
    return element;
  }
  const className = classNames(
    element.props.className,
    classes,
  );
  return React.cloneElement(element, { className });
}

