import { getBodyItemComponent } from 'components/Article/getBodyItemComponent';
import { transformHtmlCardLinksToHashLinks } from 'lib/transformHtmlCardLinksToHashLinks';

export const cardMarkup = ({
  bodyItems,
  path,
  showHiddenMarkupAndEmbeds,
  setShowHiddenMarkupAndEmbeds,
}) => {
  let showingShowMoreButton = false;

  return bodyItems?.map((bodyItem, index) => {
    const html = bodyItem?.html;
    const isShowMoreButton = bodyItem.type === 'embeddedWidget' && bodyItem.widget?.name === 'CUSTOM_EMBED' && bodyItem.widget?.properties?.embed.type === 'SHOW_MORE_BUTTON';

    if (showingShowMoreButton) {
      return null;
    }

    if (!html) {
      showingShowMoreButton = isShowMoreButton;
      return (isShowMoreButton && showHiddenMarkupAndEmbeds)
        ? null : getBodyItemComponent({
          item: bodyItem,
          i: index,
          path,
          setShowHiddenMarkupAndEmbeds,
          isCard: true,
        });
    }

    const transformedBodyItem = {
      ...bodyItem,
      html: transformHtmlCardLinksToHashLinks(bodyItem.html),
    };

    return getBodyItemComponent({
      item: transformedBodyItem, i: index, path, isCard: true,
    });
  });
};
