import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './styles.themed.scss';

const Button = (props) => {
  const { t } = useTranslation();

  const { loadingMoreCards, onClick } = props;
  const text = t(loadingMoreCards ? 'Loading ...' : 'Load More Posts');

  return (
    <div className="df justify-center mt5 tc">
      <button
        type="button"
        onClick={onClick}
        className={classNames(
          'loadMoreButton f4 ttu z-1',
          {
            'loadMoreButton--loading': loadingMoreCards,
          },
        )}
      >
        {loadingMoreCards && <span className="icon icon-peacock mr2" />}
        {text}
      </button>
    </div>
  );
};

Button.propTypes = {
  loadingMoreCards: PropTypes.bool,
  onClick: PropTypes.func,
};

Button.defaultProps = {
  loadingMoreCards: false,
  onClick: Function.prototype,
};

export default Button;
