export const formatDataForBacon = (articleData, primaryTaxonomyName) => {
  if (!articleData) return null;

  const formattedArticlesCollection = articleData.map(({
    id,
    datePublished,
    unibrow,
    headline,
    url,
    teaseImage,
    alternateTeaseImages,
    ecommerceEnabled,
    cartVisibility,
  }) => ({
    type: 'article',
    id: id || '',
    item: {
      datePublished: datePublished || '',
    },
    computedValues: {
      unibrow: unibrow || {},
      headline: (headline?.primary) || '',
      url: (url?.primary) || '',
      teaseImage: teaseImage || {},
      alternateTeaseImages: alternateTeaseImages || [],
      isShoppable: (ecommerceEnabled && cartVisibility === 'AVAILABLE_PRODUCTION') || false,
    },
  }));
  return {
    subType: 'twoOneLargeItems',
    type: 'relatedArticles',
    metadata: {
      title: primaryTaxonomyName ? `More ${primaryTaxonomyName}` : 'Related',
      showTimestamp: true,
    },
    items: formattedArticlesCollection,
  };
};
