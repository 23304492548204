import React from 'react';
import PropTypes from 'prop-types';
import { TimeAgo } from 'components/TimeAgo';
import classNames from 'classnames';
import styles from './styles.module.scss';

/**
 * @param {object} props
 * @param {DateTime} props.date
 * @param {Boolean} props.isLive
 */
const CardTimestamp = ({ date, isLive }) => (
  <div className={classNames(styles.cardTimestamp, { [styles.eventEnded]: !isLive })}>
    {date && (
      <TimeAgo dateString={date.publishedAt} />
    )}
  </div>
);

CardTimestamp.propTypes = {
  date: PropTypes.shape({
    createdAt: PropTypes.string,
    modifiedAt: PropTypes.string,
    publishedAt: PropTypes.string,
  }),
  isLive: PropTypes.bool,
};

CardTimestamp.defaultProps = {
  date: null,
  isLive: false,
};

export { CardTimestamp };
