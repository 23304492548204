/* eslint-disable max-len */
import React from 'react';

const SMS = () => (
  <svg
    width="15"
    height="15"
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="icon-sms"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill="#2A2A2A"
      d="M14 11.25H3.333L0 14.583V1a1 1 0 011-1h13a1 1 0 011 1v9.25a1 1 0 01-1 1zm-9.875-4.5a1.125 1.125 0 100-2.25 1.125 1.125 0 000 2.25zM9 5.625a1.125 1.125 0 11-2.25 0 1.125 1.125 0 012.25 0zm2.625 1.125a1.125 1.125 0 100-2.25 1.125 1.125 0 000 2.25z"
    />
  </svg>
);

export default SMS;
