/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { useFeatureFlagContext } from 'lib/ContextTypes';

import Link from 'components/Link';

import './styles.themed.scss';

export function WellsFargoBanner(props) {
  const { bodyClasses } = props;

  const { 'uni-checkout-well-fargo-banner': useUniCheckoutWellsFargoBanner } = useFeatureFlagContext();

  if (useUniCheckoutWellsFargoBanner) {
    return (
      <div
        className={classNames(
          'article-body__section--wells-fargo__banner',
          bodyClasses,
        )}
        data-testid="wells-fargo-banner"
      >
        <Link
          href="https://creditcards.wellsfargo.com/cards/active-cash-view-details/?product_code=CC&subproduct_code=AC&sub_channel=DIS&vendor_code=NB&FPID=0126D7IDF40000&placement_id=DIS_NB_CCD_AC_SHOP_ATL_E&creative_id=NBCUCOMM_E_AC2PER_LM}"
          target="_blank"
          className="article-body__section--wells-fargo__banner--link"
        >
          <picture>
            <source
              media="(min-width: 765px)"
              srcSet="https://media-cldnry.s-nbcnews.com/image/upload/rockcms/2024-02/WellsFargoDesktopBanner-34908e.png"
              alt=""
            />
            <source
              srcSet="https://media-cldnry.s-nbcnews.com/image/upload/rockcms/2024-02/WellsFargoMobileBanner-3eafcb.png"
            />
            <img
              src="https://media-cldnry.s-nbcnews.com/image/upload/rockcms/2024-02/WellsFargoDesktopBanner-34908e.png"
              alt="Wells Fargo Active Cash - Gane recompensas ilimitadas en efectivo de 2% por cada compra. Publicidad pagada. Se aplican otros términos y condiciones. Aprenda más."
            />
          </picture>
        </Link>
        <img
          width="0"
          height="0"
          src="https://secure.insightexpressai.com/adServer/adServerESI.aspx?script=false&bannerID=11924120&rnd=%5bINSERT_CACHEBUSTER_HERE%5d&redir=https://secure.insightexpressai.com/adserver/1pixel.gif"
        />
        <img
          width="0"
          height="0"
          src="https://b.videoamp.com/d2/428a9391-3021-48c0-80c2-34fa504f80f1/8749/impression?bwb=310&vpxid=8749"
        />
        <img
          width="0"
          height="0"
          src="https://b.videoamp.com/d2/66eb80aa-e384-4d22-8d2a-86d85f31f37a/8761/impression?bwb=35&vpxid=8761&dnt=false&cevt=TELEMUNDO&us_privacy={{US_PRIVACY_STRING}}"
        />
      </div>
    );
  }

  return null;
}

// eslint-disable-next-line import/no-default-export
export default WellsFargoBanner;

WellsFargoBanner.propTypes = {
  bodyClasses: PropTypes.string,
};

WellsFargoBanner.defaultProps = {
  bodyClasses: null,
};
