import React from 'react';

import { useGateAccess } from 'lib/Hooks/useGateAccess';
import { article as ArticlePropType } from 'lib/CustomPropTypes';

// HOC to support the useGateAccess hook in class components and Next.js pages
export const withGateAccess = (Component) => {
  const GateAccess = (props) => {
    const { article } = props;
    const gateAccess = useGateAccess(article);
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <Component {...props} gateAccess={gateAccess} />;
  };

  GateAccess.propTypes = {
    article: ArticlePropType.isRequired,
  };

  if (Component.getInitialProps) {
    GateAccess.getInitialProps = Component.getInitialProps;
  }

  return GateAccess;
};
