import React from 'react';
import PropTypes from 'prop-types';

import styles from './styles.module.scss';

export function ChatCardAuthorCount({ count }) {
  if (count === null) return null;
  return (
    <div className={styles.countChip} data-testid="chat-card-author-count">{`+${count}`}</div>
  );
}

ChatCardAuthorCount.propTypes = {
  count: PropTypes.number,
};

ChatCardAuthorCount.defaultProps = {
  count: null,
};
