import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import styles from './styles.module.scss';

export function ChatCardReportingFrom({ text, className }) {
  if (!text) return null;

  return (
    <div className={cx(styles.reportingFrom, className)} data-testid="chat-card-reporting-from">
      <svg aria-hidden viewBox="0 0 9 12" className={styles.icon} width="9" height="12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="m4.5 6 .7-.3.2-.7a1 1 0 0 0-.2-.7 1 1 0 0 0-.7-.3 1 1 0 0 0-.7.3 1 1 0 0 0-.3.7c0 .2 0 .5.3.7l.7.2Zm0 4.6c1.1-1 2-2 2.7-3 .7-1 1-1.8 1-2.6 0-1-.4-2-1-2.7a4 4 0 0 0-2.7-1c-1.1 0-2 .3-2.7 1C1.1 3 .8 4 .8 5c0 .8.3 1.6 1 2.6.6 1 1.5 2 2.7 3Zm0 1A19.3 19.3 0 0 1 1 8.1C.4 7 0 6 0 5c0-1.3.4-2.4 1.2-3.3C2.1 1 3.2.5 4.5.5c1.3 0 2.3.4 3.2 1.3A4.9 4.9 0 0 1 7.9 8c-.8 1.1-2 2.3-3.4 3.6Z" />
      </svg>
      <p className={cx(styles.text, 'f2 fw3 mb0')}>
        {text}
      </p>
    </div>
  );
}

ChatCardReportingFrom.propTypes = {
  text: PropTypes.string,
  className: PropTypes.string,
};

ChatCardReportingFrom.defaultProps = {
  text: '',
  className: '',
};

