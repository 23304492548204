import React from 'react';
import PropTypes from 'prop-types';
import ChildrenPropType from 'lib/CustomPropTypes/children';

import IntObserver from './IntObserver';

// 100000% top margin to pretend the viewport is "infinitely" tall, so you will
// still be considered "past" the top of the article
// -100% bottom margin to get the 0 threshold to trigger only once the top of the article
// passes the top of the viewport
export const rootMargin = '100000% 0px -100% 0px';

function WindowTopObserver({ callback, threshold, children }) {
  return (
    <IntObserver
      callback={callback}
      rootMargin={rootMargin}
      threshold={threshold}
    >
      {children}
    </IntObserver>
  );
}

WindowTopObserver.propTypes = {
  callback: PropTypes.func.isRequired,
  children: ChildrenPropType,
  threshold: PropTypes.oneOfType([PropTypes.number, PropTypes.arrayOf(PropTypes.number)]),
};

WindowTopObserver.defaultProps = {
  children: null,
  threshold: null,
};

export default WindowTopObserver;
