import React from 'react';
import SocialShareList from 'components/Social/ShareList';
import { Copy } from 'components/Social/UserActions/Copy';
import { getSiteConfigs } from 'lib/vertical';
import closeIcon from 'assets/icons/close-full-btn.svg';
import ShareIcon from 'assets/icons/share-icon-v2.svg';
import PropTypes from 'prop-types';
import cx from 'classnames';
import './styles.themed.scss';

const SocialShareIconList = ({
  socialUrl,
  primaryHeadline,
  icid,
  vertical,
}) => {
  const encodedSocialUrl = encodeURIComponent(socialUrl);
  const [showSocialShare, setShowSocialShare] = React.useState(false);
  const { twitterIntentVia: via } = getSiteConfigs(vertical);

  const socialProps = {
    // eslint-disable-next-line max-len
    twitterHref: `https://twitter.com/intent/tweet?text=${encodeURIComponent(primaryHeadline)}&via=${via}&url=${encodedSocialUrl}&original_referer=${encodedSocialUrl}`,
    facebookHref: `https://www.facebook.com/sharer/sharer.php?u=${encodedSocialUrl}`,
    emailHref: `mailto:?subject=${primaryHeadline}&body=${encodedSocialUrl}`,
    classes: 'df justify-start lh-none',
    tracking: 'mbt_navbar_socialShare',
  };

  return (
    <div className="share-list-container df">
      <div
        className="share-list__wrapper"
        data-testid="social-share-icon-list"
      >
        <div className={cx('share-list__icons', { showSocialShare })}>
          <SocialShareList
            twitterHref={socialProps.twitterHref}
            facebookHref={socialProps.facebookHref}
            emailHref={socialProps.emailHref}
            classes={socialProps.classes}
            tracking={socialProps.tracking}
            showShareThis={false}
          />
          <Copy
            contentURL={socialUrl}
            headline={primaryHeadline}
            icid={icid}
            className="share--card"
            tooltipAlign="center"
          />
        </div>
      </div>
      <button
        className="social-share-button"
        type="button"
        onClick={() => setShowSocialShare(!showSocialShare)}
        data-testid="chat-card-social-share-button"
        aria-expanded={showSocialShare}
        aria-label={`${showSocialShare ? 'close' : 'open'} social share icon list`}
      >
        <span className={cx('share-btn-txt', { showSocialShare })}>Share</span>
        {showSocialShare ? (
          <img
            alt="close icon"
            src={closeIcon}
            height={28}
            width={28}
          />
        ) : (
          <>
            <img
              alt="social share icon trigger"
              src={ShareIcon}
              height={30}
              width={30}
            />
          </>
        )}

      </button>
    </div>
  );
};

SocialShareIconList.propTypes = {
  socialUrl: PropTypes.string.isRequired,
  primaryHeadline: PropTypes.string,
  icid: PropTypes.string,
  vertical: PropTypes.string.isRequired,
};

SocialShareIconList.defaultProps = {
  primaryHeadline: '',
  icid: '',
};

export { SocialShareIconList };

