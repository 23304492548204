import React from 'react';
import PropTypes from 'prop-types';
import { useInView } from 'react-intersection-observer';
import { useDispatch, useSelector } from 'react-redux';
import { stub as $t } from '@nbcnews/analytics-framework';
import { searchProductsAction } from 'redux/modules/product';

import { pageRegion as pageRegionPropType } from 'lib/CustomPropTypes';
import { ArticleContext, RouteContext, VerticalContext } from 'lib/ContextTypes';
import { verticalSlugMap } from 'lib/vertical';
import { encodeCommerceCurationUrl } from 'lib/buildAffiliateLink';
import { ADDITIONAL_SELLERS } from 'lib/brandFeatures';
import { getFeatureConfigForBrand } from 'lib/getFeatureStatus';
import { shouldShowProductBacon } from 'lib/article/taxonomyUtils';

import { Bacon } from 'components/packages/Bacon';

$t('register', 'mbt_bacon_click', { allowDuplicate: true });
$t('register', 'mbt_bacon_scroll');

/**
 * Transform the array of related products into a format acceptable to the bacon component.
 * @param {array} items
 * @returns {object}
 */
const mapItemsForBacon = ({
  product,
  pageRoute,
  vertical,
  trackingId,
}) => ({
  item: ({
    ...product,
    offers: product.offers.map((offer) => ({
      ...offer,
      externalUrl: encodeCommerceCurationUrl({
        vertical,
        baseUrl: offer.externalUrl,
        pageRoute,
        trackingId,
      }),
    })),
  }),
  computedValues: {
    headline: product.name,
    teaseImage: product.teaseImage,
    url: null,
  },
});

function RecirculationBacon({ configurations, pageRegion }) {
  // Using useContext methods instead of imported function for tests
  const article = React.useContext(ArticleContext);
  const vertical = React.useContext(VerticalContext);
  const route = React.useContext(RouteContext);
  const [isScrollTracked, setScrollTracked] = React.useState(false);
  const isAmp = route.path.indexOf('embedded-pkg') !== -1;

  // Find a qualifying recirculation configuration
  const configuration = (
    Boolean(article)
    && Boolean(vertical)
    && configurations.find((c) => vertical === c.vertical
        && typeof c.condition === 'function'
        && c.condition({ article }))
  );

  const { ref, inView } = useInView({
    initialInView: false,
    triggerOnce: true,
    rootMargin: '250px 0px',
  }) || {};

  const dispatch = useDispatch();

  React.useEffect(() => {
    if (!configuration) {
      return;
    }

    const {
      query,
    } = configuration;

    // For AMP we load it immediately since it appears in an iframe.
    if (inView || isAmp) {
      dispatch(searchProductsAction(query));
    }
  }, [inView, isAmp]);

  React.useEffect(() => {
    // Send a window message to the parent, AMP in this case, to aid in resizing the package
    // within the iFrame. I used an asterisk for the target origin because we don't know
    // where the other window's document will be located. This is safe for us because
    // the only data we are sending is the scrollHeight, nothing malicious can be done with this.
    requestAnimationFrame(() => {
      window.parent.postMessage(
        {
          sentinel: 'amp',
          type: 'embed-size',
          height: document.body.scrollHeight > 100 ? `${document.body.scrollHeight}px` : '555px',
        },
        '*',
      );
    });
  });

  if (!configuration) {
    return null;
  }

  const {
    bacon,
    trackingId: tracking,
  } = configuration;

  const items = useSelector((state) => state?.product?.items || []);
  const shouldShowAdditionalSeller = getFeatureConfigForBrand(ADDITIONAL_SELLERS, vertical);
  const trackingId = (typeof tracking === 'function' ? tracking({ article }) : tracking);

  return (
    <div
      data-testid="recirc-bacon"
      ref={ref}
    >
      {items && items.length > 0 && (
        <div
          className="layout-grid-container mv8 dn-print"
          data-testid="recirc-bacon-wrapper"
        >
          <div className="layout-grid-item">
            <Bacon
              content={{
                ...bacon,
                metadata: {
                  ...bacon.metadata,
                  trackingId,
                },
                items: items.map((product) => {
                  const pageRoute = window.location.pathname.split('/').filter((i) => i);
                  // Remove the last path since we are inside an article path
                  pageRoute.pop();
                  return mapItemsForBacon({
                    product,
                    pageRoute: pageRoute.join('/'),
                    vertical,
                    trackingId,
                  });
                }),
              }}
              onScroll={(_, { x, width }) => {
                if (!isScrollTracked && (x / width) > 0.5) {
                  $t('track', 'mbt_bacon_scroll', {
                    action: 'scroll',
                  });
                  setScrollTracked(true);
                }
              }}
              pageRegion={pageRegion}
              vertical={vertical}
              shouldShowAdditionalSeller={shouldShowAdditionalSeller}
              trackLink={(item) => {
                $t('track', 'mbt_bacon_click', {
                  action: 'itemClick',
                  id: item.id,
                  name: item.name,
                  seller: item.offers[0]?.seller?.name,
                });
              }}
              useAltTitle={vertical === 'today'}
              titleSize="small"
              titleBgColor="green"
            />
          </div>
        </div>
      )}
    </div>
  );
}

RecirculationBacon.propTypes = {
  configurations: PropTypes.arrayOf(
    PropTypes.shape({
      vertical: PropTypes.string,
      trackingId: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
      condition: PropTypes.func,
      bacon: PropTypes.shape({
        subType: PropTypes.string,
        metadata: PropTypes.shape({
          title: PropTypes.string,
          disclaimerKey: PropTypes.string,
        }),
      }),
      query: PropTypes.shape({
        query: PropTypes.string,
        size: PropTypes.number,
        sort: PropTypes.string,
      }),
    }),
  ),
  pageRegion: pageRegionPropType,
};

RecirculationBacon.defaultProps = {
  configurations: [
    {
      vertical: verticalSlugMap.today,
      condition: ({ article }) => shouldShowProductBacon(article),
      trackingId: ({ article }) => (article.ecommerceEnabled ? 'shoparticlebestsellersbacon' : 'todayarticlebestsellersbacon'),
      bacon: {
        subType: 'oneOneProduct',
        metadata: {
          title: 'Shop TODAY Reader Favorites',
          disclaimerKey: 'DEFAULT',
        },
      },
      query: {
        query: 'type:Product AND taxonomy:today/label/shop-best-sellers',
        size: 20,
        sort: 'datePublished:desc',
      },
    },
    {
      vertical: verticalSlugMap.select,
      condition: ({ article }) => shouldShowProductBacon(article),
      trackingId: 'selectarticlebestsellersbacon',
      bacon: {
        subType: 'oneOneProduct',
        metadata: {
          title: 'Select Bestsellers',
          disclaimerKey: 'DEFAULT',
        },
      },
      query: {
        query: 'type:Product AND taxonomy:nbcnews/section/shopping/most-purchased',
        size: 20,
        sort: 'datePublished:desc',
      },
    },
  ],
  pageRegion: 'article-bottom',
};

export { RecirculationBacon };
