import React from 'react';
import PropTypes from 'prop-types';
import { markupAndEmbeds } from 'lib/CustomPropTypes';

import styles from './styles.module.scss';

const visuallyHidden = {
  display: 'inline-block',
  position: 'absolute',
  overflow: 'hidden',
  clip: 'rect(0 0 0 0)',
  height: 1,
  width: 1,
  margin: -1,
  padding: 0,
  border: 0,
};

/**
 * Modifies the HTML string to add target="_blank" before href attributes.
 *
 * @param {string} html - The HTML string to modify.
 * @returns {string} - The modified HTML string with updated anchor tags.
 */
const updateAnchorTags = (html) => html.replace(/href/g, 'target=\'_blank\' rel=\'noopener noreferrer\' href');

/**
 * Citations renders a footer with a heading and a set of body items using the cardMarkup utility function.
 * The heading is visually hidden using CSS.
 *
 * @component
 * @param {Object} props - The properties that define the Citations component.
 * @param {string} props.heading - The heading of the Citations. Defaults to 'Blog Post Citations'.
 * @param {Array} props.bodyItems - An array of objects representing the body items to be rendered by the cardMarkup utility function. Each object should follow the shape defined in the markupAndEmbeds custom prop type. Defaults to an empty array.
 *
 * @example
 * <Citations heading="Blog Post Citations" bodyItems={citations.markupAndEmbeds} />
 *
 * @returns {React.Element} The rendered Citations component.
 */

export function Citations({ id, heading, bodyItems }) {
  if (!bodyItems?.length) return null;
  return (
    <footer className={styles.citations}>
      <h3 style={visuallyHidden}>{heading}</h3>
      {bodyItems.map(({ type, element, html }) => {
        if (type !== 'markup') return null;
        const Element = element;
        return <Element key={`${id}-citation-element`} dangerouslySetInnerHTML={{ __html: updateAnchorTags(html) }} />;
      })}
    </footer>
  );
}

Citations.propTypes = {
  id: PropTypes.string,
  heading: PropTypes.string,
  bodyItems: markupAndEmbeds,
};

Citations.defaultProps = {
  id: '',
  heading: 'Blog Post Citations',
  bodyItems: [],
};
