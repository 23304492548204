import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './styles.module.scss';

const ChatCardHeadline = ({ headline }) => (
  <h2
    className={classNames(styles.chartCardHeadline)}
    data-testid="chat-card-headline"
  >
    {headline}
  </h2>
);

ChatCardHeadline.propTypes = {
  headline: PropTypes.string,
};

ChatCardHeadline.defaultProps = {
  headline: '',
};

export { ChatCardHeadline };
