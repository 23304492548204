import React from 'react';
import { Trans } from 'react-i18next';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { stub as $t } from '@nbcnews/analytics-framework';
import { stripLeadingSlash } from 'lib/urlUtils';

import styles from './styles.module.scss';

const track = (trackingID, platform) => {
  $t('track', trackingID, { platform });
};

const copy = (embedMarkup) => {
  if (window.clipboardData?.setData) {
    // IE specific code path to prevent textarea being shown while dialog is visible.
    return window.clipboardData.setData('Text', embedMarkup);
  }

  if (document.queryCommandSupported && document.queryCommandSupported('copy')) {
    const textArea = document.querySelector(`.${styles.embedCopyTextarea}`);
    try {
      textArea.value = embedMarkup;
      textArea.select();
      // Security exception may be thrown by some browsers.
      return document.execCommand('copy');
    } catch (ex) {
      // console.warn('Copy to clipboard failed.', ex);
    }
  }
  return false;
};

class VideoEmbedIcon extends React.Component {
  static propTypes = {
    href: PropTypes.string.isRequired,
    tracking: PropTypes.string.isRequired,
    classes: PropTypes.string,
  };

  static defaultProps = {
    classes: 'icon icon-embed',
  };

  constructor(props) {
    super(props);
    this.state = {
      embedCopied: false,
    };
  }

  embedClick = (e) => {
    e.preventDefault();
    const { tracking, href } = this.props;
    const { protocol, host } = window.location;
    track(tracking, 'embed');

    const sanitizedHref = stripLeadingSlash(href);

    // eslint-disable-next-line max-len
    const embedMarkup = `<iframe loading="lazy" width="560" height="315" src="${protocol}//${host}/${sanitizedHref}" scrolling="no" frameborder="0" allowfullscreen></iframe>`;

    const copied = copy(embedMarkup);

    if (copied) {
      // Show feedback
      this.setState({ embedCopied: true });
      // Hide feedback
      setTimeout(() => {
        this.setState({ embedCopied: false });
      }, 5000);
    }
  };

  render() {
    const { embedCopied } = this.state;
    const { href, classes } = this.props;

    return (
      <div className="relative dib">
        <a
          className="js-embed-link"
          data-test="video-embed__link"
          data-testid="video-embed__link"
          href={href}
          onClick={(e) => {
            this.embedClick(e);
          }}
        >
          <span className={classNames(classes)} />
        </a>
        <div
          className={classNames(styles.embedFeedback, {
            [styles.embedCopied]: embedCopied,
          })}
        >
          <Trans>copied!</Trans>
        </div>
        <textarea className={styles.embedCopyTextarea} tabIndex="-1" readOnly />
      </div>
    );
  }
}

export default VideoEmbedIcon;
